import { Button } from "primereact/button";
import ReactDOM from "react-dom";

function renderButton(date, setVideoValidator, setEventsDate) {
  const buttonContainer = document.createElement("div");
  buttonContainer.classList.add("buttonContainer");
  const validateHandler = (e) => {
    e.stopPropagation();
    setVideoValidator(true);
    setEventsDate(date);
    document.querySelectorAll(".p-tooltip").forEach((tooltip) => {
      if (tooltip instanceof HTMLElement) {
        tooltip.style.display = "none";
      }
    });
  };
  ReactDOM.render(
    <Button
      icon="pi pi-check-circle"
      id="button"
      // severity="info"
      tooltip="Validate games for this day"
      onClick={validateHandler}
      data-pr-tooltip="Validate video assets from today's games"
      data-pr-position="top"
    />,
    buttonContainer
  );
  return buttonContainer;
}

export default function handleDatesRender(
  info, setVideoValidator, setEventsDate
) {
  if (info?.view?.type === "listMonth") {
    const listHeaders = document.querySelectorAll(".fc-list-day-cushion");

    listHeaders.forEach((header) => {
      const dateElement = header.querySelector(".fc-list-day-text");
      const dateText = dateElement?.textContent || "";
      const headerDate = new Date(dateText);
      header.classList.add("listHeadersContainer");
      const dayElement = header.querySelector(".fc-list-day-text");
      const existingButton = header.querySelector(".buttonContainer");
      if (!existingButton){
        const buttonContainer = renderButton(
          headerDate,
          setVideoValidator,
          setEventsDate
        );
        if (dayElement) {
          header.insertAdjacentElement("beforeend", buttonContainer);
        }
      }
  
    });

    // const listItems = document.querySelectorAll(".fc-list-event-title");
    // // const listItems = document.querySelectorAll(".fc-list-event");
    // listItems.forEach((item) => {
    //   const eventTitle = item.querySelector(".calendarEventTitle");
    //   item.classList.add("listHeadersContainer");
    //   // const eventDetails = eventTitle?.textContent || "No title";
    //   const buttonContainer = renderButton({
    //     date:"",
    //     event: eventTitle,
    //   });
    //   item.insertAdjacentElement("beforeend", buttonContainer);
    // });
  
  }
}








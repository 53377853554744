/**
 * 
 * Simple reusable confirmation modal dialog.
 * Returns true or false.
 * 
 */
import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

export const ConfirmDialog = ({ isOpen, handleClose, props }) => {
    const { 
        title,
        subtitle,
        noLabel,
        yesLabel,
        handleConfirm,
        data } = props;

    const DialogFooter = (
        <>
            <Button 
                label={noLabel}
                icon="pi pi-times" className="p-button-text" 
                onClick={()=> handleConfirm(false, data)}
            />
            <Button label={yesLabel} 
                onClick={() => handleConfirm(true, data)}
            />
        </>
    );

    return (
        <>
        {isOpen &&
            <Dialog 
                visible={isOpen}
                style={{ width: '400px' }} 
                header={title} 
                modal 
                footer={DialogFooter} 
                onHide={() => handleClose()}
            >
                <div className="p-d-flex p-ai-center p-jc-center">
                    <span style={{ marginTop: 15, marginBottom: 5}}>
                        {subtitle}
                    </span>
                </div>
            </Dialog>
        }
        </>
    )
}